
.freeprivacypolicy-com---nb .cc-nb-main-container{
    border-radius: 20px;
    position: fixed;
    top: 35%;
    background-color: white;
    width: 90%;
    margin: auto;
    margin-left: 2%;
}
@media only screen and (max-width: 600px) {
    .freeprivacypolicy-com---nb .cc-nb-main-container {
        top: 10%;
    }
}